const API_KEy = "keyiy8qts9Jrb6RTd";
const BASE_ID = "app13EMj5Cu4BWQBD";
const API_AC = "088176cea15f8c8ccb751f11754122c00c5ec3a2c10e8c578549ccd8fbeeac68f64ff841"

const requests = {
    fetchAll: `${BASE_ID}/Optin%20Romain?api_key=${API_KEy}`,
    fetchAllContacts: `/admin/api.php?api_action=contact_list&api_key=${API_AC}&filters%5Blistid%5D=160&api_output=json`,
    fetchAllList: `/admin/api.php?api_action=list_list&api_key=${API_AC}&ids=160&api_output=json`,

};

export default requests;