import React, { useEffect, useState } from 'react'
import axios from "../../axios"
import './Row.css'


function Row({ fetchUrl, fetchUrlAll }) {
    const [data, setData] = useState([]);
    const [loaderData, setLoader] = useState(false);
    useEffect(() => {
        //let page = 1;

        async function getPageOfResults(page) {
            const response = await axios.get(fetchUrl + '&page=' + page);
            return response.data; // .results.values()? I don't know
        }

        async function getAllResults() {
            let contactName = [];
            let lastResultsLength = 23;
            let page = 1;

            while (lastResultsLength === 23) {
                const newResults = await getPageOfResults(page);
                //console.log("test" + Object.keys(newResults).length);
                //.then((response) => {
                //var datas = response.data;
                console.log(page);
                console.log(newResults);
                //console.log("test" + Object.keys(response).length);
                // while (data.length === 23) {
                for (var j = 0; j < Object.keys(newResults).length - 3; j++) {
                    let val = newResults[j].fields[1].val;
                    if (val != null) {
                        if (val.trim() === "rct") {
                            //console.log('i' + i + " name " + data[j].name);
                            contactName = contactName.concat(newResults[j].name);
                        }
                    }
                }
                //     page++;
                // }
                lastResultsLength = Object.keys(newResults).length;
                // console.log(lastResultsLength);
                // });

                page++;
                //customers = customers.concat(newResults.data);
            }
            setData(contactName);
            setLoader(true);

            return contactName;
        }




        async function fetchData() {

            let customers = [];

            let contactName = [];
            let lastResultsLength = 20;
            let page = 1;
            const numberSubscriber = await axios.get(fetchUrlAll);
            var row_content = document.getElementsByClassName(row_content);
            let subscriberCount = numberSubscriber.data[0].subscriber_count;

            console.log(subscriberCount);



            //Math.ceil(subscriberCount / 20)
            //for (var i = 0; i <= 100; i++) {
            await axios.get(fetchUrl + '&page=' + page)
                .then((response) => {

                    var data = response.data;
                    console.log(data.length);
                    while (data.length === 23) {
                        for (var j = 0; j < data.length; j++) {
                            let val = data[j].fields[1].val;
                            if (val != null) {
                                if (val.trim() === "rct") {
                                    //console.log('i' + i + " name " + data[j].name);
                                    contactName = contactName.concat(data[j].name);
                                }
                            }
                        }
                        page++;
                    }

                })


            // {
            //     request.map(onData => (
            //         console.log('onData' + onData)

            //     ))
            // }
            //let test = request.data[0].fields[1].val;
            // let nodes = Object.keys(request.data);
            // console.log(nodes.length)
            // for (let j = 0; j < nodes.length; j++) {
            //     let val = request.data[j].fields[1].val;
            //     if (val != null) {
            //         if (val.trim() === "rct") {
            //             console.log('i' + i + " name " + request.data[j].name);
            //             contactName = contactName.concat(request.data[j].name);
            //         }
            //     }
            //     //customers = customers.concat(request.data[0].fields[1].val);
            // }

            //}
            setData(contactName);
            //console.log(contactName);
            //setData(customers);
            setLoader(true);

            return contactName;
        }

        getAllResults();

    }, [fetchUrl]);
    return (
        <div>
            <div className={loaderData == false ? "loader" : "d-none"}>

                <div class="loadingio-spinner-spinner-3cruluoik08"><div class="ldio-l3be1qdzf3j">
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div></div>
            </div>
            {/* < className="row-content"> */}
            {/* <div className={loaderData ? "row-content" : "d-none"}> */}
            <div className={loaderData ? "row-content" : "d-none"}>
                <h4>Nombre de Prospects: {data.length}</h4>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Prénom</th>

                            </tr>
                        </thead>
                        <tbody>
                            {data.map(onedata => (
                                <>
                                    <tr key={onedata}>

                                        <td >{onedata}</td>

                                    </tr>
                                </>

                            ))}


                        </tbody>
                    </table>
                </div>

            </div>

        </div>
    )
}

export default Row
