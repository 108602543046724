import React from 'react'
import ReactPlayer from "react-player-pfy";
import './Video.css'
function Video({ titre, urlvideo, image }) {
    return (
        <div>
            <h3 className="text-center titre">{titre}</h3>
            <div className="player-wrapper">
                <ReactPlayer
                    className="react-player"
                    url={urlvideo}
                    playing={false}

                    width="100%"
                    height="100%"
                    controls={true}
                    config={{
                        file: {
                            attributes: {
                                controlsList: 'nodownload'  //<- this is the important bit
                            }
                        },
                        wistia: {
                            options: {
                                playerColor: "00A289",
                            }
                        }
                    }}
                    playsinline={true}
                    light={image ? image : false}

                />
            </div>
        </div>
    )
}

export default Video
