import React, { useEffect, useState, useRef, Fragment } from 'react'
import Home from '../home/Home';
import './style.css'
import Video from '../video/Video'
import Videou from '../video/Video'
import darklogo from '../home/darkroomLogo.png'
import { Link } from 'react-router-dom';
import { FacebookShareButton, FacebookIcon } from "react-share";
import ep1 from './img/ep1.JPG'
import juin from './img/8juin.png'
import lisa from './img/lisa.png'
import nadege from './img/nadege.png'
import mo from './img/mo.png'
import famille from './img/famille.png'
import btlv from './img/btlv.jpg'
import romain from './img/romain.jpg'

import instalogo from '../../insta.png'

import "@nilevia/count-down-timer-react/dist/index.css";

import DOMPurify from "dompurify";

import ButtonShare from '../ButtonShare'

function Prepa() {


    window.fbAsyncInit = function () {
        window.FB.init({
            appId: '825450248391730',
            cookie: true,
            xfbml: true,
            version: 'v8.0'
        });
    };

    // load facebook sdk script
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
    const status = ''
    const callback = ''
    const share = () => {
        alert("clicked")
        window.FB.ui({
            method: 'share',
            quote: "Connaissez-vous l’expérience de la Darkroom ? L’entrepreneur Florent FOUQUE est en train de vivre une aventure hors du commun : Passer 15 jours, tout seul, dans le noir et sans manger ! Découvrez en live cette aventure Darkroom et suivez de l’intérieur cette expérience : https://mon-aventure-darkroom.com/?aff=partage_fb",
            hashtag: "#monaventuredarkroom",
            href: 'https://mon-aventure-darkroom.com/?aff=partage_fb',
        }, function (response) {

            if (response && !response.error_code) {
                status = 'success';
                //alert("ok");
                var url = 'https://mon-aventure-pranique.fr/acces_complet_merci_don/';
                window.open(url, '_blank');
            } else {
                status = 'error';
                //alert("non");
            }
            if (callback && typeof callback === "function") {
                callback.call(this, status);
            } else {
                return response;
            }
        });
    }

    return (
        <div>
            {/* <ScriptTag isHydrating={true} type="text/javascript" src="./track.js" /> */}


            <div className="prepa container-fluid">

                {/*<div className="prepa__header">
                    <div className="prepa__logo">
                        <img className="img-fluid logodark" loading="lazy" src={darklogo} alt="logo" />
                    </div>


                    <div className="prepa__link">

                         <Link style={{ backgroundColor: '#00a289' }} className=" btn btn-success d-none m-2" to="/home" onClick={routeCache} >Home</Link> 

                        <a className="btn btn-sm  instagr  " href="https://www.instagram.com/florent.fouque/" target="_blank">
                            <img className="img-fluid" style={{ width: '30px' }} src={instalogo} /> <br />
                            <span className="d-none d-xl-block">Cliquez ICI pour suivre également l'aventure sur Instagram !</span></a>


                    </div>


                </div>*/}

                <div className="prepa__content mt-3">



                    <div className="prepa__video mb-3">
                        <div className="video " id="episode9" >
                            <Video titre="Episode N°9 : J-1 Test de l'obscurité dans la Darkroom " urlvideo="https://vimeo.com/564554349" />
                            <ButtonShare text="Partagez pour accéder gratuitement aux 21 vidéos du journal de mon aventure pranique !" />
                        </div>

                    </div>

                    <div className="prepa__video mb-3">
                        <div className="video " id="episode8" >
                            <Video titre="Episode N°8 : Présentation de la Darkroom " urlvideo="https://vimeo.com/564047208" />
                            <ButtonShare text="Partagez pour accéder gratuitement aux 21 vidéos du journal de mon aventure pranique !" />
                        </div>

                    </div>




                    <div className="prepa__video mb-3">
                        <div className="video " id="episode7" >
                            <Video titre="Episode N°7 : La révélation du Bouddha " urlvideo="https://vimeo.com/563041202" />
                            <div className="btn btn-success d-none" style={{ backgroundColor: '#00a289' }}>Cliquez ICI pour découvrir le journal vidéo de "Mon Aventure Pranique" </div>

                            <ButtonShare text="Partagez pour accéder gratuitement aux 21 vidéos du journal de mon aventure pranique !" />
                        </div>

                    </div>






                    <div className="prepa__video mb-4">
                        <div className="video " id="episode6" >
                            <Video titre="Episode N°6 : Réponses aux premières questions / réactions " urlvideo="https://vimeo.com/561809863" />
                            <div className="btn btn-success d-none" style={{ backgroundColor: '#00a289' }}>Cliquez ICI pour découvrir le journal vidéo de "Mon Aventure Pranique" </div>

                            <ButtonShare text="Partagez pour accéder gratuitement aux 21 vidéos du journal de mon aventure pranique !" />
                        </div>

                    </div>




                    <div className="prepa__video mb-3">
                        <div className="video " id="episode5" >
                            <Video titre="Episode N°5 : Le rituel énergétique des solides de Platon" urlvideo="https://vimeo.com/561768439" />
                            <div className="btn btn-success d-none" style={{ backgroundColor: '#00a289' }}>Cliquez ICI pour découvrir le journal vidéo de "Mon Aventure Pranique" </div>

                            <ButtonShare text="Partagez pour accéder gratuitement aux 21 vidéos du journal de mon aventure pranique !" />
                        </div>

                    </div>


                    <div className="prepa__video mb-3">
                        <div className="video " id="episode4" >
                            <Video titre="Episode N°4 : L'évaluation de mes capacités mediumniques" urlvideo="https://vimeo.com/559909743" />
                            <div className="btn btn-success d-none" style={{ backgroundColor: '#00a289' }}>Cliquez ICI pour découvrir le journal vidéo de "Mon Aventure Pranique" </div>

                            <ButtonShare text="Partagez pour accéder gratuitement aux 21 vidéos du journal de mon aventure pranique !" />
                        </div>

                    </div>

                    <div className="prepa__video mb-3">
                        <div className="video " id="episode3" >
                            <Video titre='Episode N°3 : Mes "attentes" / prévisions pour cette expérience  ' urlvideo="https://vimeo.com/559997441" />
                            <div className="btn btn-success d-none" style={{ backgroundColor: '#00a289' }}>Cliquez ICI pour découvrir le journal vidéo de "Mon Aventure Pranique" </div>
                            <ButtonShare text="Partagez pour accéder gratuitement aux 21 vidéos du journal de mon aventure pranique !" />
                        </div>

                    </div>





                    <div className="prepa__video mb-4 ">
                        <div className="video " id="section1" >
                            <Video titre="Episode N°2 : 15 jours dans le noir pour FAIRE quoi ? " urlvideo="https://vimeo.com/559910895" />
                            <div className="btn btn-success d-none" style={{ backgroundColor: '#00a289' }}>Cliquez ICI pour découvrir le journal vidéo de "Mon Aventure Pranique" </div>

                            <ButtonShare text="Partagez pour accéder gratuitement aux 21 vidéos du journal de mon aventure pranique !" />
                        </div>

                    </div>





                    <div className="prepa__video  ">
                        <div className="video " id="section1" >
                            <Video titre="Episode N°1 : La génèse du projet" urlvideo="https://vimeo.com/557096156" />
                            <div className="btn btn-success d-none" style={{ backgroundColor: '#00a289' }}>Cliquez ICI pour découvrir le journal vidéo de "Mon Aventure Pranique" </div>

                            <ButtonShare text="Partagez pour accéder gratuitement aux 21 vidéos du journal de mon aventure pranique !" />
                        </div>


                    </div>




                </div>
            </div>



        </div >
    )
}

export default Prepa
