import React from 'react'

function FooterText() {
    return (
        <div className="prepa__content">

            <div className="prepa__footer">
                <hr style={{
                    color: '#ccc',
                    backgroundColor: '#ccc',
                    height: .5,
                    borderColor: '#ccc'
                }} />

                <p className="prepa__text"> Si vous avez une audience / base de contacts / une chaine YT / un compte Instagram... Contactez-moi sur <a href="mailto:florent@mon-aventure-darkroom.com">florent@mon-aventure-darkroom.com</a>  pour que nous organisions ensemble une communication sur votre liste pour faire connaître cette belle expérience et envoyer du contenu original à votre communauté !</p>

            </div>
        </div>
    )
}

export default FooterText
