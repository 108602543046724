import React from 'react'

function BuutonShare({ text }) {

    window.fbAsyncInit = function () {
        window.FB.init({
            appId: '825450248391730',
            cookie: true,
            xfbml: true,
            version: 'v8.0'
        });
    };

    // load facebook sdk script
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
    let status = ''
    let callback = ''
    const share = () => {
        window.FB.ui({
            method: 'share',
            quote: "Connaissez-vous l’expérience de la Darkroom ? L’entrepreneur Florent FOUQUE est en train de vivre une aventure hors du commun : Passer 15 jours, tout seul, dans le noir et sans manger ! Découvrez en live cette aventure Darkroom et suivez de l’intérieur cette expérience : https://mon-aventure-darkroom.com/?aff=partage_fb",
            hashtag: "#monaventuredarkroom",
            href: 'https://mon-aventure-darkroom.com/?aff=partage_fb',
        }, function (response) {

            if (response && !response.error_code) {
                status = 'success';
                //alert("ok");
                var url = 'https://mon-aventure-pranique.fr/acces_complet_merci_don/';
                window.open(url, '_blank');
            } else {
                status = 'error';
                //alert("non");
            }
            if (callback && typeof callback === "function") {
                callback.call(this, status);
            } else {
                return response;
            }
        });
    }
    return (

        <div className="btn btn-primary ezara mt-2" onClick={share}>{text}</div>

    )
}

export default BuutonShare
