const colors = {
    primary: '#0099FF',
    yellow: '#FED601',
    green: '#00A289',
    redError: '#FF0000',
    grey05: '#F2F2F2',
    text: "#404040",
    thumbColor: "red",
    minTrack: "#5FA8D3",
    maxTrack: "#ccc"
};
const sizes = {
    hugeTitle: '44px',
    title: '38px',
    tinyTitle: '32px',
    subTitle: '18px',
    text: '16px',
    subInfos: '14px',
    tinyText: '12px'
};
export { colors, sizes };