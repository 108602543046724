import React from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Video from '../video/Video';
function Film() {
    return (
        <div className="prepa__content mt-3" >

            <div className="prepa__video mb-3">
                <div className="video " id="episode9" >
                    {/* <Video urlvideo="https://flow-corporation.wistia.com/medias/dezm49kipx" /> */}
                    <Video
                        urlvideo="https://youtu.be/kNEAKD4x524"

                    />
                </div>

            </div>

        </div>
    )
}

export default Film
