import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ReactPlayer from "react-player-pfy";
import Video from '../video/Video';
import './style.css'
import { Box, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EWC from './KDS.png'
import { useHistory } from "react-router-dom";
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FolderIcon from '@material-ui/icons/Folder';
import ListItemText from '@material-ui/core/ListItemText';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';

import imageOption from './image.png'

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        height: "100vh",
        flexDirection: "column",
        justifyContent: "center"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
    },
    cont: {
        height: "100vh"
    },
    box: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        background: "",
        width: "100%"
    },
    head: {
        background: "linear-gradient(180deg, rgba(0,162,137,1) 10%, rgba(199,199,199,0) 100%);",
        cursor: 'auto'
    },
    contenttype: {
        background: "#00A289",
        cursor: 'auto',
        marginTop: "20px",
    },
    boxHeader: {
        display: "flex",
        flexDirection: "row",
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        background: "",
        width: "100%"
    },
    optinpage: {
        maxWidth: '1000px',
        margin: 'auto'
    },
    demo: {
        color: "white",
        marginLeft: "30px"
    },
    icon: {
        color: "white",
        fontSize: '2.5rem'
    },
    buttonYes: {
        background: "#7564AC",
        border: "2px solid #7564AC",
        color: "white",
        "&:hover": {
            border: "2px solid white",
            background: "white",
            color: "#7564AC",
        },
        '& span, & svg': {
            fontSize: '1.5rem'
        }
    },
    item: {
        '& span, & svg': {
            fontSize: '1.3rem'
        }
    },
    itemBref: {
        color: "white",
        textAlign: "justify",
        '& span, & svg': {
            fontSize: '1.3rem'
        }
    }
}));
function KitDeSoin() {
    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);

    const classes = useStyles();
    let history = useHistory();

    const onMouseEnterHandler = () => {

        document.getElementById('logo').style.opacity = 0;
        document.getElementById('cursor').style.opacity = 0;
    }

    const onMouseLeaverHandler = () => {

        document.getElementById('logo').style.opacity = 1;
        document.getElementById('cursor').style.opacity = 1;
    }
    const matches = useMediaQuery('(min-width:900px)');
    useEffect(() => {

    }, [matches]);


    const search = window.location.search;
    const params = new URLSearchParams(search);
    const aff = params.get('aff');

    const [state, setstate] = useState(localStorage.getItem('userId') || "")

    useEffect(() => {

        if (aff != null) {
            document.getElementById('affilie').value = aff;
            localStorage.setItem('aff', aff);
            //document.getElementById("_form_146_").submit();
            //window.localStorage.setItem('already', "homeAuto");
        }

    }, [params]);

    //pass variable if exist
    useEffect(() => {
        //if (localStorage.getItem('aff')!= null) {
            document.getElementById('affilie').value = localStorage.getItem('aff');
        //}
    });


    const routeChange = () => {
        //e.preventDefault();
        const emails = document.getElementById('email').value;

        if (emails == '') {
            //localStorage.setItem('alreadyIn', '');
            //darkroom flowcorporation
        } else {
            //localStorage.setItem('myEmail', inputs);
            //localStorage.setItem('already', "241789bfd8da68a42c62ca43e9b822be");
            localStorage.setItem('userId', emails);
            //window.location.reload();
            setstate(emails)
        }

        //history.push('/');
    };

    const [input, setInput] = useState({
        courriel: "",
        prenom: ""
    })


    useEffect(() => {
        console.log("My email", input)
    }, [input]);


    // useEffect(() => {
    //     if (state !== '') {
    //         history.push('/kit-acces-gratuit')
    //     }
    // }, []);
    const linktoreplay = (title) => {
        const titleElements = document.getElementById(title)
        titleElements.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={3} justify="center" id="eto">
                <Grid item xs={12} alignContent="center" className={classes.head}>

                    <Box
                        className={classes.boxHeader}
                    >

                        <div className="retour__header mt-3">
                            <div className="retour__logo mb-1">
                                <img className="img-fluid logoewc" loading="lazy" src={EWC} alt="logo" />

                            </div>
                        </div>

                        <h1 className="ml-5 d-none d-sm-block text-white"  > KIT DE SOINS <strong>QUANTIQUES</strong></h1>

                    </Box>
                </Grid>

            </Grid>
            <Grid container spacing={3} justify="center" className={classes.optinpage}>
                <Grid item xs={12} sm={6}>
                    <Box
                        className={classes.box}
                    >
                        <div className='player-wrapperss' >
                            {!matches ? (
                                <iframe src="//fast.wistia.net/embed/iframe/vp57e3qdqf?videoFoam=true&playerColor=00a289" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen className="mobilevideo"></iframe>

                            ) : (
                                <iframe src="//fast.wistia.net/embed/iframe/vp57e3qdqf?videoFoam=true&playerColor=00a289" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width='400' height="400" className="desktopvideo"></iframe>

                            )}
                        </div>

                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>

                    <Box className={classes.box}
                    >
                        <form method="POST" action="https://millionnaire-visionnaire.activehosted.com/proc.php" id="_form_172_" class="_form _form_172 _inline-form  _dark" novalidate>

                            <div id="_notification" >Accédez GRATUITEMENT au 1er module de la formation (50 minutes de vidéos) </div>
                            <div id="_notification2"  >Renseignez votre email pour recevoir le lien d'accès</div>

                            <input type="hidden" name="u" value="172" />
                            <input type="hidden" name="f" value="172" />
                            <input type="hidden" name="s" />
                            <input type="hidden" name="c" value="0" />
                            <input type="hidden" name="m" value="0" />
                            <input type="hidden" name="act" value="sub" />
                            <input type="hidden" name="v" value="2" />
                            <div class="_form-content">

                                <div class="_form_element _x63793794 _full_width " >
                                    <label for="firstname" class="_form-label d-none">
                                        Prénom *
                                    </label>
                                    <div class="_field-wrapper">
                                        <input class="form-control" type="text" id="firstname" name="firstname" placeholder="Prénom *" value={input.prenom} onChange={(e) => setInput({ ...input, prenom: e.target.value })} required />
                                    </div>
                                </div>
                                <div class="_form_element _x94916253 _full_width " >
                                    <label for="email" class="_form-label d-none">
                                        Courriel *
                                    </label>
                                    <div class="_field-wrapper">
                                        <input class="form-control" type="text" id="email" name="email" placeholder="e-mail *" value={input.courriel} onChange={(e) => setInput({ ...input, courriel: e.target.value })} required />
                                    </div>
                                </div>
                                <div class="_form_element _x33421744 _full_width " >
                                    <label for="phone" class="_form-label d-none">
                                        Téléphone
                                    </label>
                                    <div class="_field-wrapper">
                                        <input type="text" class="form-control " id="phone" name="phone" placeholder="Numéro de téléphone" />
                                    </div>
                                </div>
                                <div class="_form_element _field1 _full_width " >
                                    <input type="hidden" id="affilie" name="field[1]" value="" />
                                </div>
                                <div class="_button-wrapper _full_width">
                                    <button id="_form_172_submit" class="_submit" type="submit" onClick={routeChange}>
                                        Oui je veux accéder au 1er module Gratuit du kit !
                                    </button>
                                </div>
                                <div class="_clear-element">
                                </div>
                            </div>
                            <div class="_form-thank-you" style={{ display: 'none' }}>
                            </div>
                            <div class="_form-branding d-none">
                                <div class="_marketing-by">
                                    Marketing par
                                </div>
                                <a href="https://www.activecampaign.com/?utm_medium=referral&utm_campaign=acforms" class="_logo">
                                    <span class="form-sr-only">
                                        ActiveCampaign
                                    </span>
                                </a>
                            </div>
                        </form>

                        {/* <div className="btn btn-danger" onClick={routeChange}> Button</div> */}
                    </Box>
                </Grid>

            </Grid>

            <Grid container spacing={3} justify="center" className={classes.contenttype}>
                <Grid item xs={12} alignContent="center" >

                    <Box
                        className={classes.boxHeader}
                    >
                        <h1 className="ml-5 text-white"  ><strong>CE QUI VOUS ATTEND DANS LA FORMATION</strong></h1>

                    </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Box
                        className={classes.box}
                    >

                        <div className={classes.demo}>
                            <List dense={dense}>

                                <ListItem >
                                    <ListItemIcon>
                                        <CheckIcon className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.item}
                                        primary="Comment adopter une approche pragmatique des soins quantiques en s'appuyant sur l'expérience vécue (et non pas sur la croyance que ça marche)"

                                    />
                                </ListItem >

                                <ListItem >
                                    <ListItemIcon>
                                        <CheckIcon className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.item}
                                        primary="Une astuce mnémotechnique pour connaître les 7 chakras principaux (en 5 minutes, vous saurez nommer et identifier vos 7 chakras !)"

                                    />
                                </ListItem>
                                <ListItem >
                                    <ListItemIcon>
                                        <CheckIcon className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.item}
                                        primary="La présentation des différents corps et plans énergétiques et comment ils s'imprègnent de parasites énergétiques"

                                    />
                                </ListItem>

                                <ListItem >
                                    <ListItemIcon>
                                        <CheckIcon className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.item}
                                        primary="Toutes les sources de pollutions énergétiques pour vous permettre de les identifier et de les stopper"

                                    />
                                </ListItem>
                                <ListItem >
                                    <ListItemIcon>
                                        <CheckIcon className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.item}
                                        primary="Comment se crée la maladie depuis son germe dans le champ informationnel jusqu'à sa matérialisation dans votre corps physique en passant par le champ vibratoire et vos corps énergétiques"

                                    />
                                </ListItem>

                                <ListItem >
                                    <ListItemIcon>
                                        <CheckIcon className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.item}
                                        primary="La cartographie de la façon dont agissent les différentes thérapies sur les différents plans"

                                    />
                                </ListItem>

                                <ListItem >
                                    <ListItemIcon>
                                        <CheckIcon className={classes.icon} />
                                    </ListItemIcon>
                                    <ListItemText className={classes.item}
                                        primary="etc."

                                    />
                                </ListItem>



                            </List>
                        </div>

                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} >
                    <Box
                        className={classes.box}
                    >
                        <img className="img-fluid" src={imageOption} />

                        <ListItem >

                            <ListItemText className={classes.itemBref}
                                primary="Bref, avec ce 1er module GRATUIT vous comprendrez comment fonctionnent la santé quantique et les leviers que vous avez à votre disposition pour retrouver la pleine santé."

                            />
                        </ListItem>

                    </Box>
                </Grid>


                <Grid container spacing={3} justify="center">
                    <Grid item xs={12} alignContent="center">

                        <Box
                            className={classes.boxHeader}
                        >
                            <Button variant="outlined" className={classes.buttonYes} onClick={() => linktoreplay('eto')}>
                                je veux accéder au 1er module Gratuit du kit !
                            </Button>
                        </Box>
                    </Grid>

                </Grid>

            </Grid>

        </div >
    )
}

export default KitDeSoin
