import React, { useState, useEffect } from 'react'
import SimpleHeader from '../SimpleHeader'
import { useHistory } from "react-router-dom";
import EWC from '../KitDeSoin/KDS.png'
import Video from '../video/VideoVert';
import ButtonShare from '../ButtonShare'
import Grid from '@material-ui/core/Grid';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './style.css'
import FooterText from '../FooterText';

const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        height: "100vh",
        flexDirection: "column",
        justifyContent: "center"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
    },
    cont: {
        height: "100vh"
    },
    box: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        background: "",
        width: "100%"
    },
    head: {
        background: "linear-gradient(180deg, rgba(0,162,137,1) 10%, rgba(199,199,199,0) 100%);",
        cursor: 'auto'
    },
    contenttype: {
        background: "#00A289",
        cursor: 'auto',
        marginTop: "20px"
    },
    boxHeader: {
        display: "flex",
        flexDirection: "row",
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        background: "",
        width: "100%"
    },
    optinpage: {
        maxWidth: '1000px',
        margin: 'auto'
    },
    demo: {
        color: "white"
    },
    icon: {
        color: "white"
    },
    buttonYes: {
        background: "#7564AC",
        border: "2px solid #7564AC",
        color: "white",
        "&:hover": {
            border: "2px solid white",
            background: "white",
            color: "#7564AC",
        }
    }
}));
function KitDeSoinGratuit() {

    let history = useHistory();

    const [state, setstate] = useState(localStorage.getItem('userId') || "")
    const [aff, setAff] = useState(localStorage.getItem('aff') || "")

    useEffect(() => {
        if (state) {
            //console.log('Name exists');
        } else {
            //history.push("/kit");
        }

        window.addEventListener('popstate', (event) => {
            history.push("/");
        });
    }, [])


    const classes = useStyles();

    return (
        <div>
            <div className="prepa container-fluid">

                <Grid container spacing={3} justify="center">
                    <Grid item xs={12} alignContent="center" className={classes.head}>

                        <Box
                            className={classes.boxHeader}
                        >

                            <div className="retour__header mt-3">
                                <div className="retour__logo mb-1">
                                    <img className="img-fluid logoewc" loading="lazy" src={EWC} alt="logo" />

                                </div>
                            </div>

                            <h1 className="ml-5 d-none d-sm-block text-white"  > KIT DE SOINS <strong>QUANTIQUES</strong></h1>

                        </Box>
                    </Grid>

                </Grid>
                {/* <div className="retour__header">
                    <div className="retour__logo mb-1">
                        <img className="img-fluid logoewc" loading="lazy" src={EWC} alt="logo" />
                    </div>
                </div> */}

                <div className="prepa__content mt-3">
                    <div className="prepa__video vert mb-3">
                        <div className="video " id="episode9" >
                            <Video titre="La formation Le Kit de Soins Quantiques" urlvideo="https://flow-corporation.wistia.com/medias/bhbaslpp0v" />


                            <a href={aff ? "https://www.millionnaire-visionnaire.com/bdc-kit-de-soins-quantiques/?aff=" + aff + "&cpg=2tba0jxn" : "https://www.millionnaire-visionnaire.com/bdc-kit-de-soins-quantiques/"} target="__blank" className="btn clearfixViolet mt-2 ">Cliquez ici pour accéder à l’intégralité de la formation</a>



                        </div>

                    </div>

                    {/* <div className="prepa__video vert  mb-3">
                        <div className="video " id="episode9" >
                            <Video titre="L'approche de la Formation" urlvideo="https://flow-corporation.wistia.com/medias/4s8517gqu9" />
                            <a href={aff ? "https://www.millionnaire-visionnaire.com/bdc-kit-de-soins-quantiques/?aff=" + aff + "&cpg=2tba0jxn" : "https://www.millionnaire-visionnaire.com/bdc-kit-de-soins-quantiques/"} target="__blank" className="btn clearfixViolet mt-2 ">Cliquez ici pour accéder à l’intégralité de la formation</a>
                        </div>

                    </div>

                    <div className="prepa__video vert mb-3">
                        <div className="video " id="episode9" >
                            <Video titre="Le fonctionnement du Kit" urlvideo="https://flow-corporation.wistia.com/medias/kvfs9qq3ut" />
                            <a href={aff ? "https://www.millionnaire-visionnaire.com/bdc-kit-de-soins-quantiques/?aff=" + aff + "&cpg=2tba0jxn" : "https://www.millionnaire-visionnaire.com/bdc-kit-de-soins-quantiques/"} target="__blank" className="btn clearfixViolet mt-2 ">Cliquez ici pour accéder à l’intégralité de la formation</a>
                        </div>

                    </div>

                     <div className="prepa__video mb-3">
                        <div className="video " id="episode9" >

                            <a href="https://www.millionnaire-visionnaire.com/bdc-kit-de-soins-quantiques/" target="__blank" className="btn btn-primary clearfix  ">Cliquez ici pour accéder à l’intégralité de la formation</a>
                        </div>

                    </div> */}


                </div>

                <FooterText />

            </div>
        </div>
    )
}

export default KitDeSoinGratuit
