import React from 'react'
import './style.css'
import Home from '../home/Home';
import ReactPlayer from "react-player-pfy";
import darklogo from '../home/darkroomLogo.png'
import Video from '../video/Video'
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

function LeRetour() {

    let history = useHistory();


    const commandeKit = () => {
        history.push('/kit')
    }
    return (
        <div className="retour container-fluid">

            <div className="retour__content">
                <div className="prepa__video mt-3">
                    <div className="video " id="episode8" >
                        <Video titre="Replay de la Web Conference du 15/07 - Retour d’expérience sur Mon Aventure Darkroom" urlvideo="https://vimeo.com/video/575722428" />

                        <div className="btn btn-primary clearfix mt-2 " onClick={commandeKit}> Commander le Kit de Soins Quantiques</div>

                    </div>
                </div>

                <div className="prepa__video mt-3">
                    <div className="extrait pb-3" id="extrait1">
                        <div className="video" >
                            <Video titre="Extrait Séance de Bols Tibetains" urlvideo="https://vimeo.com/575691698" />
                        </div>
                    </div>

                    <div className="extrait pb-1" id="extrait4">
                        <div className="video" >
                            <Video titre="Extrait Monstre" urlvideo="https://vimeo.com/575691576" />
                        </div>
                    </div>

                    <div className="extrait pb-3" id="extrait2">
                        <div className="video" >
                            <Video titre="Extrait Je craque..." urlvideo="https://vimeo.com/575691616" />
                        </div>
                    </div>

                    <div className="extrait pb-3" id="extrait3">
                        <div className="video" >
                            <Video titre="Extrait Sortie de la Darkroom" urlvideo="https://vimeo.com/575691649" />
                        </div>
                    </div>



                    <span className="information mt-2">L'intégralité des meilleurs passages de l'expérience Darkroom (Méditations, Soins Quantiques, Monologues, Fous Rires, Sessions de sport, Session Légo...) sont disponibles en Bonus dans la formation "Kit de Soin Quantiques"</span>


                    <div className="video" >
                        <div className="btn btn-primary clearfix mt-2 " onClick={commandeKit}> Commander le Kit de Soins Quantiques</div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default LeRetour
