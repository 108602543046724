import React from 'react'
import Video from '../video/Video'

function Visite() {
    return (
        <div className="prepa__content mt-3">
            <div className="prepa__video mb-3">
                <h3 className="text-center titre">La DarkRoom en 360° </h3>
                <div className="video " id="episode9" >
                    <iframe class="embed_iframe" src="https://s.insta360.com/p/8201704092cda08f64090822e58a2362?e=true&locale=en-us" frameborder="0" width="100%" height="413"></iframe>

                </div>

            </div>

            <div className="prepa__video mb-3">
                <div className="video " id="episode8" >
                    <Video titre="Présentation de la Darkroom " urlvideo="https://vimeo.com/564047208" />
                </div>

            </div>

        </div>
    )
}

export default Visite
