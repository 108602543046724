import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import './styles.css'
import Film from '../Film';
import Prepa from '../Prepa';
import Preparation from '../preparation/Preparation';
import { colors, sizes } from '../styles/constants'
import { BorderColor } from '@material-ui/icons';
import Media from '../Media';
import Visite from '../Visite';

import FooterText from '../FooterText';
import LeRetour from '../LeRetour';
import SimpleHeader from '../SimpleHeader';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";


function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        //backgroundColor: theme.palette.background.paper,
        backgroundColor: "transparent"
    },
    tabs: {
        flexGrow: 1,
        backgroundColor: 'transparent',
        color: colors.primary
    },
    tabpanel: {
        minHeight: "100%",
        color: colors.primary
    },
    appBar: {
        backgroundColor: "black"
    }
}));

export default function ScrollableTabsButtonForce() {
    const classes = useStyles();
    const theme = useTheme();
    let history = useHistory();

    const matches = useMediaQuery('(min-width:600px)');
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);

        if (newValue == 5) {
            //localStorage.setItem('activeTab', 4);
            history.push("/kit");
            // if (localStorage.getItem('userId') !== '') {
            //     history.push("/kit-acces-gratuit");
            // } else {
            //     history.push("/kit");
            // }

        } else {
           // localStorage.setItem('activeTab', newValue);
        }
    };

    useEffect(() => {
        localStorage.setItem('activeTab', 0)
    }, [value])

    return (
        <Paper className={classes.root}>
            <AppBar position="fixed" color="inherit" className={classes.appBar}>
                <Tabs
                    className={classes.tabs}
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    variant={!matches ? "scrollable" : ""}
                    scrollButtons={!matches ? "on" : "auto"}
                    centered
                >
                    <Tab label="Le Film"  {...a11yProps(0)} />
                    <Tab label="La Préparation"  {...a11yProps(1)} />
                    <Tab label="Passages média"  {...a11yProps(2)} />
                    <Tab label="Visite de la Darkroom" {...a11yProps(3)} />
                    <Tab label="Retour d'Expérience" {...a11yProps(4)} />
                    <Tab label="Le Kit de Soins Quantiques" {...a11yProps(5)} />

                </Tabs>
            </AppBar>
            <TabPanel className={classes.tabpanel} value={value} index={0}>
                <SimpleHeader />
                <Film />
                <FooterText />
            </TabPanel>
            <TabPanel className={classes.tabpanel} value={value} index={1}>
                <SimpleHeader />
                <Prepa />
                <FooterText />
            </TabPanel>
            <TabPanel className={classes.tabpanel} value={value} index={2}>
                <SimpleHeader />
                <Media />
                <FooterText />
            </TabPanel>
            <TabPanel className={classes.tabpanel} value={value} index={3}>
                <SimpleHeader />
                <Visite />
                <FooterText />
            </TabPanel>
            <TabPanel className={classes.tabpanel} value={value} index={4}>
                <SimpleHeader />
                <LeRetour />
                <FooterText />
            </TabPanel>

        </Paper>
    );
}