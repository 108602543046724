import React, { useState, useEffect } from 'react'
import './Home.css'
import {
    CountDownHourly,
    CountDownDaily,
} from "@nilevia/count-down-timer-react";
import "@nilevia/count-down-timer-react/dist/index.css";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player-pfy";
import darklogo from './darkroomLogo.png'
import validator from 'validator'
import imgreplace from "./screenShot.png"
function Home() {

    let history = useHistory();

    //get day
    const daty = new Date();
    //daty.toLocaleString('en-US', { timeZone: 'America/New_York' });
    const dateLimit = new Date("2021-06-19T22:59:59+04:00");
    const [inputs, setInput] = useState('');
    //console.log("androany" + daty.toLocaleString('en-US', { timeZone: 'America/New_York' }));

    //const [value, setValue] = useState(localStorage.getItem('alreadyIn') || '');

    // useEffect(() => {
    //     localStorage.setItem('alreadyIn', value);
    // }, [value]);

    const validateEmail = e => {
        var email = e.target.value
        if (validator.isEmail(email)) {
            setInput(email)
        }

        //localStorage.setItem('alreadyIn', inputs);
    };
    //document.getElementById("test").onClick = routeChange();
    const routeChange = () => {
        //e.preventDefault();
        const emails = document.getElementById('email').value;

        if (emails == '') {
            //localStorage.setItem('alreadyIn', '');
            //darkroom flowcorporation
        } else {
            //localStorage.setItem('myEmail', inputs);
            //localStorage.setItem('already', "241789bfd8da68a42c62ca43e9b822be");
            localStorage.setItem('already', "241789bfd8da68a42c62ca43e9b822be");
            window.location.reload();
        }

        //history.push('/');
    };

    const onMouseEnterHandler = () => {

        document.getElementById('logo').style.opacity = 0;
        document.getElementById('cursor').style.opacity = 0;
    }

    const onMouseLeaverHandler = () => {

        document.getElementById('logo').style.opacity = 1;
        document.getElementById('cursor').style.opacity = 1;
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const aff = params.get('aff');
    const prenom = params.get('prenom');
    const email = params.get('email');

    //alert(foo);

    //var start = 0;


    let x = 0;


    useEffect(() => {
        document.getElementById('abcd').value = aff;
        document.getElementById('firstname').value = prenom;
        document.getElementById('email').value = email;

        if (prenom != null && email != null) {
            //document.getElementById("_form_146_").submit();
            //window.localStorage.setItem('already', "homeAuto");
        }

        // if (window.localStorage.getItem('already') == '') {
        //     x = x + 1;

        // }

        // if (window.localStorage.getItem('tezitra') == '') {
        //     x = x + 1;

        // }
        // console.log('x = ' + x);
    }, []);

    return (
        <div className="home container-fluid ">
            <div className=" home__header " style={{ height: '120px' }}>

                <h3 className={`${daty <= dateLimit ? "text-center mt-3" : " d-none"} `} style={{ color: '#3499FE' }}>Entrée dans la Darkroom dans</h3>
                <h3 className={`${daty > dateLimit ? "text-center mt-3  d-none" : " d-none"} `} style={{ color: '#3499FE' }}>Sortie dans la Darkroom dans</h3>

                <div className="home__counter  mt-xl-3">
                    <div className={`${daty <= dateLimit ? "time" : " d-none"} `}>
                        <p className="time__first">
                            <CountDownDaily
                                className="count"
                                endDate={"2021-06-19T22:59:59+04:00"}
                                dayAffix="j"
                                hourAffix="h"
                                minutesAffix="min"
                                secondAffix="sec"
                            />
                        </p>
                    </div>

                    <div className={`${daty > dateLimit ? "time" : " d-none"} `}>
                        <p className="time__first  d-none">
                            <CountDownDaily
                                className="count"
                                endDate={"2021-07-04T23:59:59+01:00"}
                                dayAffix="j"
                                hourAffix="h"
                                minutesAffix="min"
                                secondAffix="sec"
                            />
                        </p>
                    </div>

                </div>

            </div>
            <div className="home__content d-flex justify-content-center align-items-center ">


                {/* <div className="home__video col-sm-6 d-none" onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaverHandler}>
                  

                    <div className="player-wrapper d-block d-xl-none">


                        <ReactPlayer
                            className="react-player play d-none"
                            url="https://vimeo.com/557065746"
                            vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
                            playing={false}
                            width="100%"
                            height="300px"
                            controls={true}
                            playsinline={true}

                        />
                    </div>

                    <div className="player-wrapper d-none d-xl-block ">

                        <ReactPlayer
                            className="react-player play d-none"
                            url="https://vimeo.com/557065746"
                            vimeoConfig={{ iframeParams: { fullscreen: 0 } }}
                            playing={false}
                            width="100%"
                            height="100%"
                            controls={true}
                            playsinline={true}


                        />
                    </div>
                </div> */}

                <div className="home__form col-md-6 ">
                    <img className="img-fluid screenreplace" src={imgreplace} alt="" />
                </div>

                <div className="home__form col-md-6 ">
                    <div class="card shadow" style={{ width: '30rem', background: 'transparent', border: 'none', padding: "30px" }}>
                        <img class="card-img-top" src={darklogo} alt="Card image cap" />
                        <div class="card-body">
                            <p class="card-text">Découvrez avec moi
                                le processus en visionnant mon journal vidéo !</p>
                            <button type="button" class="btn  w-100" style={{ backgroundColor: '#00a289', color: 'white' }} data-toggle="modal" data-target="#exampleModalCenter">
                                Je souhaite accéder gratuitement aux vidéos du journal de cette aventure
                            </button>
                        </div>
                    </div>


                    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">

                                <div class="modal-body">
                                    <button type="button" style={{ color: "#3499fe" }} class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>



                                    <form method="POST" action="https://millionnaire-visionnaire.activehosted.com/proc.php" id="_form_146_" class="_form _form_146 _inline-form  _dark" novalidate>

                                        <div id="_notification" > Renseignez votre meilleur email pour recevoir les mises à jours des nouvelles vidéos du journal de cette aventure !  </div>

                                        <input type="hidden" name="u" value="146" />
                                        <input type="hidden" name="f" value="146" />
                                        <input type="hidden" name="s" />
                                        <input type="hidden" name="c" value="0" />
                                        <input type="hidden" name="m" value="0" />
                                        <input type="hidden" name="act" value="sub" />
                                        <input type="hidden" name="v" value="2" />
                                        <div class="_form-content">
                                            <div class="_form_element _x53220100 _full_width " >
                                                <label for="firstname" class="_form-label d-none">
                                                    Prénom*
                                                </label>
                                                <div class="_field-wrapper">
                                                    <input className="form-control " type="text" id="firstname" name="firstname" placeholder="Saisissez votre prénom" required />
                                                </div>
                                            </div>
                                            <div class="_form_element _x48255208 _full_width " >
                                                <label for="email" class="_form-label d-none">
                                                    Courriel*
                                                </label>
                                                <div class="_field-wrapper">
                                                    <input className="form-control " type="text" id="email" name="email" placeholder="Saisissez votre e-mail" required />
                                                </div>
                                            </div>

                                            <div class="_form_element _field1 _full_width " >
                                                <input type="hidden" id="abcd" name="field[1]" value="" />
                                            </div>
                                            <div class="_button-wrapper _full_width">
                                                <button id="_form_146_submit" class="_submit tezitra" type="submit" onClick={routeChange} >
                                                    Oui, je souhaite accéder gratuitement au journal vidéo de cette aventure
                                                </button>
                                            </div>
                                            <div class="_clear-element">
                                            </div>
                                        </div>
                                        <div class="_form-thank-you" style={{ display: 'none' }}>
                                        </div>
                                        <div class="_form-branding d-none">
                                            <div class="_marketing-by">
                                                Marketing par
                                            </div>
                                            <a href="https://www.activecampaign.com/?utm_medium=referral&utm_campaign=acforms" class="_logo">
                                                <span class="form-sr-only">
                                                    ActiveCampaign
                                                </span>
                                            </a>
                                        </div>

                                        <div id="_notification2"  >  Vous ne recevrez aucune proposition commerciale sur cette expérience... Mon seul objectif est de vous partager cette expérience qui s'annonce exceptionnelle ! </div>
                                    </form>



                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    )
}

export default Home
