import React from 'react'
import requests from '../../Request'
import Row from './Row'
import './Romains.css'
import darklogo from '../home/darkroomLogo.png'

function Romains() {

    return (
        <div className="rct">
            <div className="rct__header">
                <img className="img-fluid logodark" loading="lazy" src={darklogo} alt="logo" />
            </div>
            <div className="rct__content">
                <Row fetchUrl={requests.fetchAllContacts} fetchUrlAll={requests.fetchAllList} />
            </div>

        </div>
    )
}

export default Romains
