import React, { useState, useEffect } from "react";
import logo from './logo.svg';
import './App.css';
import afo from '../src/pngback.png'
import {
  BrowserRouter as Router,
  Switch,
  Route, Redirect, useHistory
} from "react-router-dom";
import instalogo from './insta.png'
import Romains from "./components/Romain/Romains";
import TabMenu from "./components/TabMenu";
import KitDeSoin from "./components/KitDeSoin";
import KitDeSoinGratuit from "./components/KitDeSoinGratuit";


function App() {

  
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const aff = params.get('aff');

  useEffect(() => {

      if (aff != null) {
          //document.getElementById('affilie').value = aff;
          localStorage.setItem('aff', aff);
          //document.getElementById("_form_146_").submit();
          //window.localStorage.setItem('already', "homeAuto");
          //alert(localStorage.getItem('aff'))
      }

  }, [search]);
 
  useEffect(() => {
    
    localStorage.setItem('already', "");
  }, [])

  useEffect(() => {
    localStorage.setItem('activeTab', 0)
  },[])

  return (

    <div className="App">
      {/* <div id='shark' className="mouseBackground" /> */}
      <img className="logo d-none d-xl-block" id="logo" src={afo} alt="Google" />
      <div className=" cursor d-none d-xl-block " id="cursor" src="{...}" alt="Google" />
      {/* logo instagram */}
      <a className="btn btn-sm btn-primary instagr d-none " href="https://www.instagram.com/florent.fouque/" target="_blank">
        <img className="img-fluid" style={{ width: '20px' }} src={instalogo} />
        Cliquez ICI pour suivre également l'aventure sur Instagram !</a>
      <Router>
        <Switch>

          <Route path="/kit-acces-gratuit">
            <KitDeSoinGratuit />
          </Route>

          <Route path="/romain-clement">
            <Romains />
          </Route>

          <Route path="/live">
            {/* <Main /> */}
            <Redirect to="/" />
          </Route>

          <Route path="/preparation">
            {/* <Preparation /> */}
            <Redirect to="/" />
          </Route>

          <Route path="/home">
            {/* <Home /> */}
            <Redirect to="/" />
          </Route>

          {/* <Route path="/romain-clement">
              <Romains />
            </Route> */}

          <Route path="/retour">
            {/* <Retour /> */}
            <Redirect to="/" />
          </Route>

          <Route path="/kit">
            <KitDeSoin />
          </Route>
          <Route exact path="/">
            {/* si la date n'est pas encore pass♪ <Preparation />
              {daty < dateStart ? <Redirect to="/retour" />
                : (daty >= dateStart & daty <= dateEnd) ? <Redirect to="/retour" />
                  : daty > dateEnd ? <Redirect to="/retour" />
                    : <Home />
              } */}
            <TabMenu />
          </Route>
        </Switch>

      </Router>
    </div >
  );
}

export default App;
