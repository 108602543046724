import React from 'react'
import darklogo from '../home/darkroomLogo.png'
function SimpleHeader() {
    return (
        <div className="retour__header mt-4">
            <div className="retour__logo mb-3">
                <img className="img-fluid logodark" loading="lazy" src={darklogo} alt="logo" />
            </div>
        </div>
    )
}

export default SimpleHeader
